<template>

  <div class="text-center animated fadeIn h-100">
    <div class="d-flex flex-column  " :class=" sm ? 'h-100':'h-50-vh' ">

      <div v-show=" isLoading " class=" my-auto text-success ">
        <i class="fa fa-circle-notch  fa-2x mb-2 fa-spin fa-2x " v-if="sm"></i>
        <i class="fa fa-circle-notch  fa-2x mb-2 fa-spin fa-4x " v-else></i>
        <h6 class=" font-weight-bold " v-text=" (loadingMessage || $t('tables.loading')) + '...'" v-if="sm"> </h6>
        <h5 class=" font-weight-bold " v-text=" (loadingMessage || $t('tables.loading')) + '...'" v-else> </h5>
      </div>

      <div v-show=" !isLoading " class=" my-auto ">
        <div class="row">
          <div class="col-12  mx-auto " :class=" sm ? '':'col-sm-10 col-md-8 col-lg-6' ">
            <div class="row justify-content-center">
              <div class="col-auto">
                <img src="@/assets/images/no-data.png" class="img-cover-50" alt="" v-if="sm">
                <img src="@/assets/images/no-data.png" class="img-cover-70" alt="" v-else>
              </div>
            </div>
            <p :class=" sm ? '' : 'py-3' ">
              <small v-if="sm">
                <span v-html=" dataMessage "></span>
              </small>
              <span v-else>
                <i class="fa fa-info-circle mr-1 "></i>
                <span v-html=" dataMessage "></span>
              </span>
            </p>
          </div>
        </div>
        <div v-if="btnMessage">
          <button v-if=" !isLink " class=" btn btn-success btn " @click=" $emit( 'btn-fn' ) "
            v-text=" btnMessage || $t('general.button.add') ">
          </button>
          <a class="btn btn-success btn " :href="routerLink" target="_blank" v-if=" isLink "
            v-text=" btnMessage || $t('general.button.add') "></a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "NoData",
    props: {
      showBtn: {
        type: Boolean,
        default: false,
      },
      isLink: {
        type: Boolean,
        default: false,
      },
      btnMessage: {
        type: String,
      },
      routerLink: {
        type: String,
        default: null,
      },
      dataMessage: {
        type: String,
      },
      loadingMessage: {
        type: String,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      sm: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return ({
      })
    },
    mounted() {
      // setTimeout( () => { this.isLoading = false } , 1000  )
    }
  }
</script>

<style>

</style>